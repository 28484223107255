<template>
  <div class="l-about">
    <img id="header"
         class="l-about__banner"
         src="../../assets/images/about-us-banner2.webp"
         alt=""
    />
    <div class="l-about__ctn">
      <h1>About Us</h1>
      <p>
        DRAGON DREAM DIGITAL Ltd, a Costa Rican-based social gaming publishing company, is dedicated to promote
        innovative games that leverage the power of blockchain technology and are free to play.
      </p>
      <p>
        At Blockspin, we're guided by our values of innovation, passion, and integrity. Our experienced team of game
        developers, blockchain experts, and passionate gamers share a common vision for the future of gaming.
      </p>
      <p>
        Our games allow players to participate in a vibrant, decentralized gaming ecosystem. Our commitment to innovation
        and passion drives us to constantly explore new technologies and partnerships to provide the best possible gaming
        experience for our players.
      </p>
      <p>
        We strive to maintain the highest ethical standards in all of our operations. Whether you're a seasoned blockchain
        enthusiast or a casual gamer, we invite you to join us on our journey to revolutionize the gaming industry with
        integrity and innovation.
      </p>
      <h2>Our products</h2>
      <div class="l-about--products-wrap">
        <img src="../../assets/images/blockspin-icon.webp" alt="Blockspin Gaming"/>
        <div class="l-about--products-card">
          <img src="../../assets/images/jetsetSlots-icon.webp" alt="JetSet Slots"/>
          FACEBOOK APP
          <p>SOON</p>
        </div>
        <div class="l-about--products-card">
          <img src="../../assets/images/bot-icon.webp" alt="Discord social casino slot"/>
          DISCORD APP
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  mounted() {
    const scrollTo = document.getElementById("header");
    if(scrollTo) {
      scrollTo.scrollIntoView();
    }
  }
};

</script>

<style lang="scss" scoped>
.l-about {
  background: #ffffff;

  min-height: 100vh;
  /* Background image is fixed in the viewport so that it doesn't move when
     the content's height is greater than the image's height */
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
h2{
  margin: 20px;
  font-family: 'Rubik', sans-serif;
  color: $black;
}
h3{
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  color: $black;
}

.l-about__ctn {
  position: relative;
  width: 800px;
  margin: 0 auto;
  background-color: $white;
  padding: 7rem 4rem;
  margin-bottom: 3rem;
  border-radius: 6px;
  color: $black;
  text-rendering: optimizeLegibility;
  line-height: 1.67;
  letter-spacing: .267px;
  font-family: Lato, helvetica, arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;

  p {
    margin-bottom: 16px;
  }

  h1,h2{
    color: inherit;
    font-family: "lato", sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 36px;
  }

  ul {
    list-style: disc;
    margin-bottom: 12px;
    margin-left: 30px;
  }

  @include media-max(820px) {
    width: 95%;
  }
}
.l-about__banner{
  width: 100%;
}

.l-about--products-wrap{
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  img{
    height: 100%;
  }
}

.l-about--products-card{
  height: 100px;
  display: flex;
  flex-direction: column;
  width: min-content;
  font-size: 12px;
  line-height: 1.2;
  gap: 7px;
  text-align: center;
  font-weight: 600;
}


.l-about__back-btn {
  position: absolute;
  top: 20px;
  left: 4.5vw;
  z-index: 15;
}
</style>